import React from "react"
// import PropTypes from 'prop-types'
import slugify from "slugify"
import styled from "styled-components"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { Responsive } from "@clevertrack/shared"
import Layout from "components/Layout"
import SEO from "components/SEO"
import Post from "components/Blog/Post"
import Sidebar from "components/Blog/Post/Sidebar"
import SliceZone from "components/Blog/SliceZone"
import BlogContainer from "components/Blog/BlogContainer"
import RelatedPosts from "components/Blog/Post/RelatedPosts"
import BoxPreview from "components/Blog/Previews/BoxPreview"
import { linkResolver } from "utils/linkResolver"
import PersonCTA from "components/Person/CTA"

import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

const BodyLayout = styled.div`
  /* background: rgba(0, 255, 0, 0.1); */

  ${(props) => props.theme.media.tablet_landscape_up`
    margin-top: 4rem;
    padding-bottom: 10rem;
    display: flex;
    margin-left: 2rem;
    .sidebar {
      order: 0;
      flex: 0 0 30rem;
    }
    .slices {
      order: 1;
      flex: 1 0 auto;
      max-width: 80rem;
    }
  `}
`

function Article({ data, ...props }) {
  if (!data) return null
  const { site, prismicSiteSettings, prismicBlogPost: post } = data
  const { data: site_settings } = prismicSiteSettings
  const { body } = post.data
  const { readingTime } = post
  const { meta_title, meta_description, meta_images } = post.data
  const { siteUrl } = site.siteMetadata
  const uri = linkResolver(post)

  const { body: contactPage } = data.prismicIndex.data
  const personSlice = contactPage.find((x) => x.slice_type === "person_cta")

  const tableOfContents = body
    .filter((slice) => slice.slice_type === "content")
    .map((slice) => {
      if (slice.primary.afsnit) {
        return {
          text: slice.primary.afsnit,
          entry: slugify(slice.primary.afsnit, { lower: true, locale: "da" }),
        }
      }
    })
    .filter(Boolean)

  return (
    <>
      {post.data.public_publish ? (
        <SEO
          lang={post.lang}
          slug={uri}
          title={meta_title}
          description={meta_description}
          images={meta_images}
        />
      ) : (
        <meta name="robots" content="noindex,nofollow" />
      )}

      <Layout site_settings={site_settings} lang={post.lang}>
        <Post post={post} />

        <BlogContainer>
          <BodyLayout>
            <Responsive
              phone={
                <Sidebar
                  className="sidebar"
                  toc={tableOfContents}
                  readingTime={readingTime}
                />
              }
              tabletLandscape={<></>}
            />
            <article className="slices">
              <SliceZone
                slices={body}
                pageInfo={{ uri, host: siteUrl, title: post.data.title.text }}
              />
            </article>
            <Responsive
              phone={<Sidebar className="sidebar" post={post} />}
              tabletLandscape={
                <Sidebar
                  className="sidebar"
                  toc={tableOfContents}
                  post={post}
                  readingTime={readingTime}
                />
              }
            />
          </BodyLayout>
          {post.data.related_posts &&
            post.data.related_posts.length > 0 &&
            post.data.related_posts[0].a_related_post.document && (
              //check if any related posts exists
              <RelatedPosts>
                {post.data.related_posts.map((p, i) => (
                  <BoxPreview
                    post={p.a_related_post.document}
                    key={`related_post_${i}`}
                  />
                ))}
              </RelatedPosts>
            )}
        </BlogContainer>
        {personSlice && (
          <PersonCTA ctas={personSlice.items} {...personSlice.primary} />
        )}
      </Layout>
    </>
  )
}

export { Article }
export default withPrismicPreview(Article)

Article.defaultProps = {}

Article.propTypes = {}

export const query = graphql`
  query PrismicBlog($lang: String, $uid: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicSiteSettings {
      _previewable
      ...SiteNavigation
      ...FooterInformation
      ...FooterNavigation
      ...SocialMediaLinks
      ...UtilityNavigation
    }
    prismicBlogPost(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      ...BlogPostFragment
      ...BlogPostBodyFragment
      data {
        meta_title
        meta_description
        meta_images {
          image {
            dimensions {
              width
              height
            }
            alt
            copyright
            url
            localFile {
              childImageSharp {
                id
                main: fixed(width: 1200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        show_in_sitemap
        related_posts {
          a_related_post {
            document {
              ... on PrismicBlogPost {
                ...BlogPostFragment
                ...BlogPostBodyFragment
              }
            }
          }
        }
        body {
          ... on PrismicBlogPostDataBodyBlogposts {
            ...BlogRelatedBlogposts
          }
        }
      }
    }
    # for hardcoded contact CTA
    # getting data from the /kontakt page (da-dk)
    prismicIndex(lang: { eq: "da-dk" }, uid: { eq: "index" }) {
      data {
        body {
          ... on PrismicIndexDataBodyPersonCta {
            slice_type
            primary {
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
              person_cta_content {
                richText
              }
              person_cta_title {
                richText
              }
            }
            items {
              person_cta_action
              person_cta_link_text
            }
          }
        }
      }
    }
  }
`
