import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "components/Layout"
import SEO from "components/SEO"
import Container from "components/Container"
import { Button } from "@clevertrack/shared"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"

const StyledContainer = styled(Container)`
  padding-top: 10rem;
  margin: 4rem;
`

const StyledButton = styled(Button)`
  margin-top: 10rem;
`

const NotFoundPage = ({ ...props }) => {
  const { data: site_settings } = props.data.prismicSiteSettings
  const browser = typeof window !== "undefined" && window
  return (
    browser && (
      <Layout lang="da-dk" site_settings={site_settings}>
        <SEO title="404: Not found" />
        <StyledContainer>
          <h1>
            Siden eksisterer ikke ...{" "}
            <span role="img" aria-label="emoji">
              👻
            </span>
          </h1>
          <p>Vi kunne ikke finde indholdet til dig.</p>
          <Link to={"/"}>
            <StyledButton variant="secondary">Til forsiden</StyledButton>
          </Link>
        </StyledContainer>
      </Layout>
    )
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)

export const query = graphql`
  query Page404 {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicSiteSettings {
      _previewable
      ...SiteNavigation
      ...FooterInformation
      ...FooterNavigation
      ...SocialMediaLinks
      ...UtilityNavigation
    }
    prismicIndex(lang: { eq: "da-dk" }, uid: { eq: "index" }) {
      data {
        body {
          ... on PrismicIndexDataBodyPersonCta {
            slice_type
            primary {
              person {
                document {
                  ... on PrismicPerson {
                    ...PersonFragment
                  }
                }
              }
              person_cta_content {
                richText
              }
              person_cta_title {
                richText
              }
            }
            items {
              person_cta_action
              person_cta_link_text
            }
          }
        }
      }
    }
  }
`
