const fonts = [
  {
    source: `static`,
    fonts: [
      {
        filename: `euclidsquare`,
        title: `EuclidSquare`,
        suffix: `-webfont`,
        config: {
          weights: [
            `bold`,
            `bolditalic`,
            `light`,
            `lightitalic`,
            `medium`,
            `mediumitalic`,
            `regular`,
            `regularitalic`,
            `semibold`,
            `semibolditalic`,
          ],
        },
      },
    ],
  },
]

const fontStacks = {
  heading: `'EuclidSquare regular', serif`,
  body: `'EuclidSquare regular', sans-serif`,
  light: `'EuclidSquare light', sans-serif`,
  body: `'EuclidSquare regular', sans-serif`,
  bold: `'EuclidSquare bold', sans-serif`,
}

export { fonts, fontStacks }
