import React, { useState } from "react"
import Marquee from "react-fast-marquee"
import { Responsive, Tooltip } from "@clevertrack/shared"
import styled from "styled-components"
import "twin.macro"
import TextLoop from "react-text-loop"
import Content from "components/Content"
import ResponsiveImage from "components/ResponsiveImage"
import shuffle from "lodash-es/shuffle"
import tw from "twin.macro"

const StyledImageRepeater = styled.div`
  padding: 2rem 0;
  .images__list {
    margin: 0 auto;
    padding: 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    li {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: baseline;
      width: 100%;
      padding: 1rem;
      max-width: 20rem;
      height: auto;
      max-height: 24rem;

      .image {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 12rem;

        img {
          height: 10rem;
          width: 100%;
          left: 0;
          right: 0;
          object-fit: contain;
        }
      }

      .description {
        text-align: center;
        margin-top: 1rem;
        font-family: ${(props) => props.theme.fontStacks.light};
        p {
          margin: 0;
        }
      }
    }

    &.-vertical {
      grid-template-columns: 1fr;
      display: flex;
      flex-direction: column;
      max-width: 80rem;

      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: none;
        max-height: none;
        padding-top: 2rem;

        .image {
          width: 12rem;
          height: 12rem;
        }

        .description {
          text-align: left;
        }
      }
    }
  }

  ${(props) => props.theme.media.tablet_portrait_up`
    padding: 4rem 0;

    .images__list {
      grid-template-columns: repeat(2, .5fr);
      grid-column-gap: 1%;

      &.-vertical {
        li {
          display: grid;
          grid-template-columns: minmax(12rem, 14rem) 1fr;
          grid-column-gap: 4rem;
          align-items: center;
          max-width: none;
          max-height: none;
          padding-top: 0;

          .description {
            text-align: left;
          }
        }
      }
    }
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    text-align: center;

    .images__list {
      padding-top: 4rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;

      > * {
        flex: 0 0 25%;
        padding: 3rem 2rem 0;
      }
    }
  `}
`

const StyledTooltip = styled(Tooltip)`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  ${tw`p-4 px-6 bg-white text-black text-xl`}
  left: 50%;
  bottom: auto;
  width: auto;
  transform: translate3d(-50%, -100%, 0);
  white-space: nowrap;

  p {
    ${tw`m-0`}
  }

  &:after {
    ${tw`border-t-white right-0 left-auto lg:(left-1/2)`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    left: 50%;
    max-width: none;
    transform: translate3d(-50%, -100%, 0);
  `}
`

const ImageContainer = styled.div`
  margin-top: 2rem;
  // height: 8rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    // width: 100%;
  `}
`

const StyledImage = styled.div`
  transition: 200ms;
  padding: 2rem 4rem;
  transform: scale(0.95);
  filter: grayscale(1);
  height: 100%;
  position: relative;

  &:hover {
    transform: scale(1);
    filter: grayscale(0);
    // transition: 200ms;
  }

  img {
    width: auto;
    height: 10rem;
  }
`

function ImageRepeater({
  images_title,
  images_content,
  direction,
  items,
  shuffle_text,
  ...props
}) {
  const shuffleItems = shuffle_text ? shuffle_text.split(",") : []
  const [showTooltipID, setShowTooltipID] = useState(null)

  const onEnterTooltipHandler = (e, key) => {
    setShowTooltipID(key)
  }

  const onLeaveTooltipHandler = (e) => {
    setShowTooltipID(null)
  }

  return (
    <StyledImageRepeater borderless>
      <div tw="flex flex-col items-center text-center w-full">
        {images_title && images_title.text && (
          <Content content={images_title.richText} />
        )}
        {shuffleItems.length > 0 && (
          <h2 tw="text-brand-500 -mt-8 lg:(ml-4)">
            <Responsive
              phone={
                <TextLoop mask={true}>
                  {shuffleItems.map((x) => (
                    <span key={x.split(" ").join("_")}>{x}</span>
                  ))}
                </TextLoop>
              }
              tabletLandscape={
                <TextLoop mask={true}>
                  {shuffleItems.map((x) => (
                    <span key={x.split(" ").join("_")}>{x}</span>
                  ))}
                </TextLoop>
              }
            />
          </h2>
        )}
      </div>
      <ImageContainer>
        <Marquee autoFill>
          {shuffle(items).map((item, i) => {
            const {
              image,
              description: { text },
            } = item
            return (
              <StyledImage key={`image_${i}_row1`}>
                <ResponsiveImage img={image} alt={text} />
              </StyledImage>
            )
          })}
        </Marquee>
      </ImageContainer>
    </StyledImageRepeater>
  )
}

export default ImageRepeater

ImageRepeater.defaultProps = {}
ImageRepeater.propTypes = {}
