import React from "react"
import styled from "styled-components"
import { Elements } from "prismic-reactjs"
import { graphql } from "gatsby"
import Content from "components/Content"
import tw from "twin.macro"
import ResponsiveImage from "components/ResponsiveImage"

const StyledQuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledQuote = styled.blockquote`
  max-width: 75rem;
  margin-left: auto;
  font-family: ${(props) => props.theme.fontStacks.light};
  font-size: 1.8rem;
  line-height: 1.6;
  position: relative;
  ${tw`italic mt-8 mb-12 block md:(mt-36 mb-16)`}

  .cite {
    position: absolute;
    font-size: 51.2rem;
    top: 65%;
    text-align: left;
    right: 5%;
    left: auto;
    line-height: 0;
    opacity: 0.08;
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    font-size: 2.4rem;
  `}
`

const StyledCite = styled.div`
  margin-left: auto;
  margin-right: 4rem;
  ${tw`ml-auto mr-8 mb-20 flex flex-row-reverse items-center text-right`}

  .person {
    margin-left: 3rem;
  }

  p {
    ${tw`m-0`}
  }

  a {
    ${tw`text-brand-500`}
  }
`

const quoteSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.paragraph:
      return (
        <StyledQuote>
          <span className="cite">”</span>
          <span className="inner">{children}</span>
        </StyledQuote>
      )
    default:
      return null
  }
}

const citeSerializer = (type, element, content, children, key) => {
  switch (type) {
    case Elements.paragraph:
      return <span>{children}</span>
    default:
      return null
  }
}

function Quote({ content, ...props }) {
  const { quote, name, company_name, role_position, client_logo } = content
  return (
    <StyledQuoteWrapper>
      <Content
        content={quote.richText}
        serializer={quoteSerializer}
        {...props}
      />
      <StyledCite>
        <div className="person">
          <span tw="flex items-center">
            <span tw="mr-2">— </span>
            <Content
              content={name.richText}
              htmlSerializer={citeSerializer}
              {...props}
            />
          </span>
          <span tw="flex items-center text-xl">
            <Content
              content={role_position.richText}
              htmlSerializer={citeSerializer}
              {...props}
            />
          </span>
          <span tw="flex items-center text-xl">
            <Content
              content={company_name.richText}
              htmlSerializer={citeSerializer}
              {...props}
            />
          </span>
        </div>
        <span tw="w-24 h-24 flex place-content-center shadow-card rounded-full">
          <ResponsiveImage
            tw="w-16 h-16 my-auto object-contain"
            img={client_logo}
          />
        </span>
      </StyledCite>
    </StyledQuoteWrapper>
  )
}

export default Quote

export const query = graphql`
  fragment TestimonialFragment on PrismicBlogPostDataBodyTestimonial {
    slice_type
    id
    primary {
      theme
      title {
        richText
      }
      role_position {
        richText
      }
      quote {
        richText
      }
      name {
        richText
      }
      eyebrow_headline {
        richText
      }
      description {
        richText
      }
      company_name {
        richText
      }
      client_logo {
        url
      }
      profile_pic {
        localFile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
